import CalculatorImage from '$/assets/images/illustrations/update/calculator_white.svg';
import { Text } from '$/components/Atoms/Typography';
import NoOfferCard from '$/components/Organisms/NoOfferCard';
import useFeatureFlag from '$/hooks/useFeatureFlag';
import { CurrentLeaseStatus } from '$/model/LeaseStatus';
import { useRouter } from 'next/router';
import { memo } from 'react';

import type { Props } from './types';
import * as Styled from '../styles';
import HomeStatus from '../types';

function DetailedCards({
  reversed,
  handle,
  isCashRedeemable,
  cashCardText,
  homeStatus,
  leaseStatus,
  currentIncentives,
  currentLease,
  currentRenewalOffer,
}: Props): JSX.Element {
  const { push } = useRouter();
  const ntvEnabled = useFeatureFlag('NOTICE_TO_VACATE');
  const movingCalculatorEnabled = useFeatureFlag('MOVING_CALCULATOR');

  const renewalOffered = homeStatus === HomeStatus.RenewalOffered;
  const renewalNotOffered = homeStatus === HomeStatus.RenewalNotOffered;

  const LeaseCards = (): JSX.Element => {
    switch (true) {
      case homeStatus === HomeStatus.NewLeaseAccepted:
        return (
          <Styled.NewLeaseCard
            address={currentLease.unit.address.street}
            bathroomsMin={currentLease.unit.baths}
            bedroomsMin={currentLease.unit.beds}
            city={currentLease.unit.address.city}
            dateToRenew={currentLease.fromDate}
            imageUrl={currentLease.unit.coverImageId}
            postalCode={currentLease.unit.address.zipCode}
            priceMin={currentLease.rent}
            state={currentLease.unit.address.state}
            title={currentLease.unit.name}
            hasResponsive
          />
        );
      case renewalNotOffered && leaseStatus === CurrentLeaseStatus.EXPIRED:
        return <NoOfferCard />;
      default:
        return (
          <Styled.LeaseCard
            unit={currentLease.unit}
            buttonText="View your renewal offers"
            hasMoveOutOption={ntvEnabled}
            renewalExpiryDate={currentRenewalOffer?.effectiveTo}
            leaseToDate={currentLease.toDate}
            moveOutDate={currentLease.moveOutInfo?.moveOutDate}
            onClick={
              homeStatus === HomeStatus.RenewalOffered
                ? handle.viewRenewalOffers
                : undefined
            }
            price={currentLease.rent}
            homeStatus={homeStatus}
          />
        );
    }
  };

  return (
    <Styled.Cards $reversed={reversed}>
      {currentLease.unit && <LeaseCards />}
      {renewalOffered && movingCalculatorEnabled ? (
        <Styled.CalculatorCardWrapper>
          <CalculatorImage />
          <Text $size="xl" $weight="bold">
            Estimate how much moving costs
          </Text>
          <Text $size="md" $weight="regular">
            Considering moving? Estimate your cost to move with Renew’s moving
            calculator
          </Text>
          <Styled.CalculatorButton
            label="Calculate your moving cost"
            onClick={() => push('/moving-calculator')}
          />
        </Styled.CalculatorCardWrapper>
      ) : (
        <Styled.CashCard
          amount={currentIncentives.balance}
          text={isCashRedeemable ? '' : cashCardText}
          variant={renewalNotOffered ? 'withBonusIcons' : 'default'}
          secondaryText={
            renewalNotOffered
              ? 'Keep an eye out for coins like these to see how to earn more. '
              : undefined
          }
          onClick={handle.viewWallet}
        />
      )}
    </Styled.Cards>
  );
}

export default memo(DetailedCards);
